import React from 'react'

const LoadingSpinner = () => {
  return (
    <div>
      Loading...
    </div>
  )
}

export default LoadingSpinner
