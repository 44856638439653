import moment from "moment"
import React from "react"
import styled from "@emotion/styled"
import qs from "query-string"

import { replace } from "gatsby"
import { request } from "../../services/api"

import Layout from "../../components/Layout/Layout"
import LoadingSpinner from "../../components/ui/LoadingSpinner"

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  min-height: 100vh;
  background-color: ${props => props.theme.colors.background};
  padding-top: 100px;
  padding-bottom: 200px;
`

const OrderId = styled.div`
  display: flex;
  flex-direction: column;

  padding: 1rem;
  margin: 0 1rem;

  background-color: ${props => props.theme.colors.brand};
  border-left: 10px solid ${props => props.theme.colors.secondary};

  .note,
  .thanks,
  .container,
  .summary,
  .total {
    padding: 1rem;
  }

  .thanks {
    font-size: ${props => props.theme.typography.header};
    color: ${props => props.theme.colors.light};
  }

  .container {
    font-size: ${props => props.theme.typography.header};
    background-color: ${props => props.theme.colors.light};

    .header {
      font-weight: bold;
    }

    .number {
      font-size: ${props => props.theme.typography.major};
      color: ${props => props.theme.colors.secondary};
    }

    .type {
      margin-top: 1rem;
      font-size: ${props => props.theme.typography.body};
    }
  }

  .summary {
    color: ${props => props.theme.colors.light};

    border-left: 1px solid gray;
    border-right: 1px solid gray;
    border-bottom: 1px solid gray;

    .item {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      margin-bottom: 1rem;
      .title {
        font-size: ${props => props.theme.typography.header};
        margin-right: 1rem;
      }
      .price {
        font-size: ${props => props.theme.typography.body};
        color: ${props => props.theme.colors.secondary};
      }
    }
  }

  .total {
    border-left: 1px solid gray;
    border-right: 1px solid gray;
    border-bottom: 1px solid gray;
    width: 100%;

    .line {
      color: ${props => props.theme.colors.light};
      .amount {
        color: ${props => props.theme.colors.secondary};
      }
      display: flex;
      justify-content: space-between;
    }
  }

  .note {
    border-left: 1px solid gray;
    border-right: 1px solid gray;
    border-bottom: 1px solid gray;
    width: 100%;
    color: ${props => props.theme.colors.light};
    p {
      padding: 0.5rem;
      color: ${props => props.theme.colors.secondary};
      font-size: ${props => props.theme.typography.body};
    }
  }

  ${props => props.theme.breakpoints.tablet} {
    min-width: 500px;
    padding: 2rem;

    .note,
    .thanks,
    .container,
    .summary,
    .total {
      padding: 2rem;
    }
  }
`

class OrderCompleted extends React.Component {
  state = {
    order: null,
  }

  componentDidMount() {
    const { t } = qs.parse(this.props.location.search)
    if (!t) {
      replace("/")
    }

    request("GET", `/orders/${t}`).then(({ data }) => {
      this.setState({
        order: data.order,
      })
    })
  }

  render() {
    return (
      <Layout locale={"en"}>
        <Container>
          {this.state.order ? (
            <OrderId>
              <div className="thanks">
                Thank you for your order, {this.state.order.address.fullName}!
              </div>
              <div className="container">
                <div className="header">ORDER NUMBER</div>
                <div className="number">{this.state.order._id}</div>
                <div className="type">
                  {this.state.order.isDelivery ? "Deliver at " : "Pick up at "}
                  {moment(this.state.order.time).format("HH:mm")}
                </div>
                <div className="type">
                  {this.state.order.paymentType === "pay_at_pickup"
                    ? "Pay at pickup"
                    : "Paid with Credit Card"}
                </div>
              </div>
              <div className="summary">
                {this.state.order.items.map(({ _id, menuItem }) => (
                  <div className="item" key={_id}>
                    <div className="title">{menuItem.name}</div>
                    <div className="price">DKK {menuItem.price.toFixed(2)}</div>
                  </div>
                ))}
              </div>
              <div className="total">
                {this.state.order.deliveryFee > 0 && (
                  <div className="line">
                    <div>Delivery fee</div>
                    <div className="amount">
                      DKK {this.state.order.deliveryFee.toFixed(2)}
                    </div>
                  </div>
                )}
                {this.state.order.discount > 0 && (
                  <div className="line">
                    <div>Discount {this.state.order.couponCode}</div>
                    <div className="amount">-{this.state.order.discount}%</div>
                  </div>
                )}
                <div className="line">
                  <div>Your total</div>
                  <div className="amount">
                    DKK {this.state.order.total.toFixed(2)}
                  </div>
                </div>
              </div>
              {this.state.order.note && (
                <div className="note">
                  <div>Note</div>
                  <p>{this.state.order.note}</p>
                </div>
              )}
            </OrderId>
          ) : (
            <LoadingSpinner />
          )}
        </Container>
      </Layout>
    )
  }
}

export default OrderCompleted
